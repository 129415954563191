<template>
  <div class="mobMontages">
    <DateFilter @changeDate="changeDate($event)" class="mobMontages_dateFilter" />
    <div class="mobMontages_container">
      <v-expansion-panels v-if="montages && montages.length > 0">
        <v-expansion-panel
          v-for="(montage, index_montage) in montages"
          :key="index_montage"
          class="mobMontages_container_montage"
        >
          <div class="mobMontages_container_montage_status" style="width: 0.4em;">
            <div
              :style="`background-color: ${montageStatusColors[montage.status]}`"
            ></div>
          </div>
          <v-expansion-panel-header>
            <v-row class="pa-0 ma-0">
              <v-col cols="12" class="pa-0 py-1 ma-0">
                <span style="font-weight: 500;">Клиент: </span>
                {{montage.client.name}}
              </v-col>
              <v-col cols="12" class="pa-0 py-1 ma-0">
                <span style="font-weight: 500;">Адрес: </span>
                {{montage.address}}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>

            <Montage :montage="montage" />
            
            <v-card-actions v-if="montage.status != 'ready' && montage.status != 'no_work'">
              <v-btn text color="red"
                v-if="montage.status === 'work'" @click="updateStatusMontage(montage, 'no_work')">Не выполнено</v-btn>
              <v-btn text color="blue" v-else @click="updateStatusMontage(montage, 'work')">В работе</v-btn>
              <v-spacer></v-spacer>
              <v-btn text color="green" v-if="montage.status === 'work'" @click="updateStatusMontage(montage, 'ready')">Готово</v-btn>
              <v-btn text color="green" v-else disabled @click="updateStatusMontage(montage, 'ready')">Готово</v-btn>
            </v-card-actions>

          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div v-else class="mobMontages_container_noMontages">
        Нет монтажей
      </div>
    </div>
  </div>
</template>

<script>
import DateFilter from './DateFilter.vue'
import Montage from './Montage.vue'

export default {
  name: 'HomeMobPageMontages',
  data: () => ({
    date: new Date(new Date().toISOString().substr(0, 10)),
    montageStatusColors: {
      assigned: 'orange',
      work: '#2046e6',
      no_work: 'red',
      ready: 'green'
    }
  }),
  components: {
    Montage,
    DateFilter
  },
  created() {
    if (!this.montages || this.montages.length === 0) {
      this.$store.dispatch('montages/loadMontagesForMontagerProfile', {
        uid: this.user.uid,
        date: this.date
      })
    }
  },
  computed: {
    montages() {
      return this.$store.getters['montages/getMontagesForMontagerProfile']
    },
    user() {
      return this.$store.getters['user/getUser']
    }
  },
  methods: {
    changeDate(date) {
      this.date = date
      this.$store.dispatch('montages/loadMontagesForMontagerProfile', {
        uid: this.user.uid,
        date: this.date
      })
    },
    updateStatusMontage(montage, status) {
      this.$store.dispatch('montages/updateMontage', {
				id: montage.id,
				data: {
        	status: status
      	},
				manager: montage.manager,
				oldData: montage
			})
    }
  }
}
</script>

<style lang="scss">
.mobMontages {
  &_dateFilter {
    position: sticky;
    top: 56px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    z-index: 2;
  }

  &_container {
    border-radius: 0 !important;
    
    &_montage {
      border-radius: 0 !important;
      position: relative;

      .v-expansion-panel-content__wrap {
        padding: 0 !important;
      } 


      &_status {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;

        div {
          width: 100%;
          height: 100%;
        }
      }
    }

    &_noMontages {
      display: flex;
      justify-content: center;
      padding-top: 60%;
      color: grey;
      text-transform: uppercase;
    }
  }
}
</style>